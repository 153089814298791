import React from "react";
import { Link } from "react-router-dom";
import { Col } from "rsuite";

// helpers
import { handleLegends } from "../helper";

const Stats = ({ state, items, startDate, endDate }) => {
  return items.map((item, index) => {
    item.noData ? (item.iconColor = "grey") : item.iconColor;
    return (
      <Col key={index} lg={6} sm={12} xs={24}>
        <div className="shadow-sm bg-white radius-5 p-3 d-flex align-items-center tile-h mb-md-4 mb-2">
          <div className={"icon-box me-3 " + item.iconColor}>
            <i className={item.iconName}></i>
          </div>
          <div>
            <h2 className="head-xl">
              {item.heading === "Certificates Issued" && item.count ? (
                <Link
                  className="f-semi"
                  to="/students"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLegends(
                      "certificates",
                      "certificatesCount",
                      state,
                      startDate,
                      endDate,
                    );
                  }}
                >
                  {item.count}
                </Link>
              ) : (
                <>
                  {item.noData ? 0 : item.count}
                  {item.heading == "Courses Published" &&
                  item?.totalCourses &&
                  item?.totalCourses != 0
                    ? `/` + item.totalCourses
                    : ""}
                </>
              )}
            </h2>
            <p className="head-sm">{item.heading}</p>
          </div>
        </div>
      </Col>
    );
  });
};

export default Stats;
