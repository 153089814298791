import React from "react";
import { useDrag, useDrop } from "react-dnd";

const ITEM_TYPE_ROW = "row";

export const DndRow = ({ children, onDrag, rowData }) => {
  const ref = React.useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ITEM_TYPE_ROW,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item) => {
      onDrag?.(item.id, rowData.id);
    },
  }));

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ITEM_TYPE_ROW,
    item: { id: rowData.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));
  const isActive = canDrop && isOver;

  drag(drop(ref));

  const styles = {
    cursor: "grab",
    opacity: isDragging ? 0.5 : 1,
    background: isActive ? "#ddd" : null,
    width: "100%",
    height: "100%",
    borderTop: isActive ? "2px solid #2589f5" : null,
  };

  return (
    <div ref={ref} style={styles}>
      {children}
    </div>
  );
};

export const handleDndRow = (data, sourceId, targetId) => {
  const sourceItem = data?.filter((item) => item.id == sourceId)?.[0];
  const targetItem = data?.filter((item) => item.id == targetId)?.[0];
  if (sourceItem == null || targetItem == null) return;

  let newSourcePosition = targetItem?.attributes?.position;
  if (newSourcePosition < 1) newSourcePosition = 1;
  if (sourceItem?.attributes?.position == newSourcePosition) return null;
  return {
    source: sourceItem,
    newSourcePosition: newSourcePosition,
  };
};
