import React, { useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetcher";
import { Table } from "rsuite";
import Spinner from "../../../components/spinner";
import CourseItemForm from "./course_item_form";
import DeleteConfirmationModal from "../../../components/delete_confirmation_modal";
import ConfirmModal from "../../../components/confirm_modal";
import axios from "axios";
import { getAuthenticityToken } from "../../../utils/authenticity_token";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndRow, handleDndRow } from "../../../utils/dnd";
import { COURSE_CONTENT_TYPES_MAPPER } from "../../../utils/constants";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const LessonContent = ({ lesson, courseId, backToLessons, key }) => {
  const [courseItemToEdit, setCourseItemToEdit] = useState(null);
  const [courseItemToDelete, setCourseItemToDelete] = useState(null);
  const [itemToDeleteErrors, setItemToDeleteErrors] = useState(null);

  const {
    data: courseItemsData = { data: [] },
    isLoading: courseItemsIsLoading,
    mutate: mutateCourseItems,
  } = useSWR(
    {
      url: "/api/v0/course_items",
      params: {
        course_id: courseId,
        lesson_id: lesson.id,
      },
    },
    fetcher,
  );

  const [showDndConfirmModal, setShowDndConfirmModal] = useState(null);
  const handleDndCourseItem = (sourceId, targetId) => {
    let dndCourseItem = handleDndRow(courseItemsData?.data, sourceId, targetId);
    if (dndCourseItem) {
      setShowDndConfirmModal(dndCourseItem);
    }
  };

  if (courseItemsIsLoading) return <Spinner />;

  if (!courseItemsData?.data?.length) return backToLessons();

  if (key != courseItemsData?.data?.length) mutateCourseItems();

  return (
    <>
      <div className="mx--3">
        {/* lessons table */}
        <div className="shadow-sm bg-white radius-5">
          <div className="inner">
            <h2 className="head-lg mb-3">{lesson?.attributes?.title}</h2>
            <DndProvider backend={HTML5Backend}>
              <Table
                loading={courseItemsIsLoading}
                height={500}
                width={"100wv"}
                data={courseItemsData?.data || []}
                renderRow={(children, rowData) => {
                  return rowData ? (
                    <DndRow
                      key={rowData.id}
                      rowData={rowData}
                      id={rowData.id}
                      onDrag={handleDndCourseItem}
                    >
                      {children}
                    </DndRow>
                  ) : (
                    children
                  );
                }}
              >
                <Column width={100} flexGrow={0.25}>
                  <HeaderCell></HeaderCell>
                  <Cell>
                    <div className="light-txt px-2 py-1">
                      <i className="icon-button fa-solid fa-grip-vertical me-2"></i>
                    </div>
                  </Cell>
                </Column>
                <Column width={100} flexGrow={1}>
                  <HeaderCell>Order</HeaderCell>
                  <Cell dataKey="attributes.position" />
                </Column>
                <Column width={100} flexGrow={1}>
                  <HeaderCell>Content Type</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return COURSE_CONTENT_TYPES_MAPPER[
                        rowData["attributes"]["type"]
                      ];
                    }}
                  </Cell>
                </Column>
                <Column width={100} align="center" flexGrow={2}>
                  <HeaderCell>Assessment</HeaderCell>
                  <Cell dataKey="attributes.assessment" />
                </Column>
                <Column width={100} align="center" flexGrow={1}>
                  <HeaderCell>Actions</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return (
                        <div className="light-txt px-2 py-2">
                          <i
                            className="icon-button fa-solid fa-pen me-2"
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => setCourseItemToEdit(rowData)}
                            onClick={() => setCourseItemToEdit(rowData)}
                          ></i>
                          <i
                            className="icon-button fa-solid fa-trash me-2"
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => setCourseItemToDelete(rowData)}
                            onClick={() => setCourseItemToDelete(rowData)}
                          ></i>
                        </div>
                      );
                    }}
                  </Cell>
                </Column>
              </Table>
            </DndProvider>
          </div>
        </div>
      </div>
      {/* edit course item */}
      {courseItemToEdit && (
        <CourseItemForm
          courseId={courseId}
          open={!!courseItemToEdit}
          courseItem={courseItemToEdit}
          onClose={() => {
            setCourseItemToEdit(null);
          }}
          onConfirm={() => {
            setCourseItemToEdit(null);
            mutateCourseItems();
          }}
        />
      )}
      {/* delete course item */}
      {courseItemToDelete && (
        <DeleteConfirmationModal
          open={!!courseItemToDelete}
          onClose={() => setCourseItemToDelete(null)}
          onConfirm={() => {
            setItemToDeleteErrors(null);
            axios({
              method: "DELETE",
              url: `/api/v0/course_items/${courseItemToDelete.id}`,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
            })
              .then(() => {
                setCourseItemToDelete(null);
                mutateCourseItems();
                // TODO: if last course item go back
                return null;
              })
              .catch(e=>{
                setItemToDeleteErrors(e.response?.data?.errors?.base);
              });
          }}
          errors={itemToDeleteErrors}
        />
      )}
      {/* course item dnd  */}
      {showDndConfirmModal && (
        <ConfirmModal
          open={!!showDndConfirmModal}
          message={`Are you sure you want to move the 
          ${COURSE_CONTENT_TYPES_MAPPER[showDndConfirmModal.source.attributes.type]}
           type course item from position ${showDndConfirmModal.source.attributes.position}
            to ${showDndConfirmModal.newSourcePosition}?`}
          onClose={() => setShowDndConfirmModal(null)}
          onConfirm={() => {
            axios({
              method: "PUT",
              url: `/api/v0/course_items/${showDndConfirmModal.source.id}`,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
              data: {
                course_item: {
                  position: showDndConfirmModal.newSourcePosition,
                },
              },
            })
              .then(() => {
                setShowDndConfirmModal(null);
                mutateCourseItems();
                return;
              })
              .catch((err) => {
                console.log(err.response.data.errors);
              });
          }}
        />
      )}
    </>
  );
};

export default LessonContent;
