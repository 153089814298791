import React from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import PageTitle from "../../../components/page_title";
import SubpageNavbar from "../../../components/subpage_navbar";
import { isAdmin } from "../../../utils/state";
import { Button } from "rsuite";

const Show = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") ?? "list";
  const page = searchParams.get("page") ?? "1";

  const items = [
    { to: `summary?type=${type}&page=${page}`, label: "Summary" },
    { to: `content?type=${type}&page=${page}`, label: "Content" },
    ...(isAdmin()
      ? [{ to: `students?type=${type}&page=${page}`, label: "Students" }]
      : []),
  ];
  const navigate = useNavigate();

  const handleNav = () => {
    navigate(
      `/courses/?type=${searchParams.get("type")}&page=${searchParams.get("page")}`,
    );
  };

  return (
    <div className="container">
      <div className="mb-3">
        <div className="row align-items-center">
          <div className="col-md-4">
            <PageTitle title={"Course details"} />
          </div>
          <div className="col-md-8">
            <div className="d-flex justify-content-end">
              <Button
                appearance="ghost"
                className="px-3 me-2"
                color="green"
                onClick={handleNav}
              >
                <i className="fa fa-angle-left"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-sm bg-white radius-5 tab-sec">
        <div className="top">
          <SubpageNavbar items={items} />
        </div>
        <div className="inner">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Show;
