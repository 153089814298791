import React from "react";

export const COUNTRYNAMES = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire",
  "Bosnia",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Macedonia",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena",
  "Saint Kitts",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre",
  "Saint Vincent",
  "Samoa",
  "San Marino",
  "Sao Tome",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad",
  "Tunisia",
  "Turkmenistan",
  "Turks",
  "Tuvalu",
  "Türkiye",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain",
  "United States Minor Outlying Islands",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands",
  "Wallis",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const AFGSTATENAMES = [
  "Badakhshan",
  "Badghis",
  "Baghlan",
  "Balkh",
  "Bamyan",
  "Daykundi",
  "Farah",
  "Faryab",
  "Ghazni",
  "Ghōr",
  "Helmand",
  "Herat",
  "Jowzjan",
  "Kabul",
  "Kandahar",
  "Kapisa",
  "Khost",
  "Kunar",
  "Kunduz",
  "Laghman",
  "Logar",
  "Maidan Wardak",
  "Nangarhar",
  "Nimruz",
  "Nuristan",
  "Paktia",
  "Paktika",
  "Panjshir",
  "Parwan",
  "Samangan",
  "Sar-e Pol",
  "Takhar",
  "Urozgan",
  "Zabul",
];

export const certificateGeneratedOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const gradeOptionFormatter = (grade) => ({
  value: grade["id"],
  label: grade["attributes"]["name"],
});

export const courseOptionFormatter = (course) => ({
  value: course["id"],
  label: course["attributes"]["title"],
});

export const otherTypeOptions = [
  { value: "elementary", label: "Elementary School" },
  { value: "middle", label: "Middle School" },
  { value: "high", label: "High School" },
];

export const FormatArray = (array) => {
  return array.map((obj) => {
    const formattedObj = { ...obj };
    for (const key in formattedObj?.attributes) {
      if (formattedObj.attributes[key] === null) {
        formattedObj.attributes[key] = "N/A";
      }
    }
    return formattedObj;
  });
};

export const isToday = (value) => {
  return (
    value[0].toDateString() === new Date().toDateString() &&
    value[1].toDateString() === new Date().toDateString()
  );
};

export const isYesterday = (value) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    value[0].toDateString() === yesterday.toDateString() &&
    value[1].toDateString() === yesterday.toDateString()
  );
};

export const isLastWeek = (value) => {
  const today = new Date();
  const lastDay = new Date();
  lastDay.setDate(lastDay.getDate() - 6);
  return (
    value[0].toDateString() === lastDay.toDateString() &&
    value[1].toDateString() === today.toDateString()
  );
};

export const initialState = {
  page: 1,
  limit: 5,
  courseType: "",
  gradeIds: [],
  locationId: "",
  age: "",
  gender: "",
  locations: {
    countries: {},
    afg_states: {},
  },
  provinceIds: [],
  courseIds: [],
  gradeOptions: [],
  courseOptions: [],
  applied: false,
  appliedData: {},
};

export const COLORS = ["#FF8042", "#18181a"];

export const StatsObject = (res) => {
  return [
    {
      iconColor: res?.enrollments ? "green" : "grey",
      iconName: "fa-solid fa-graduation-cap",
      count: res?.enrollments,
      heading: "Enrollments",
      // noData:false
    },
    {
      iconColor: res?.published_courses ? "red" : "grey",
      totalCourses: res?.total_published_courses,
      iconName: "fa-solid fa-solid fa-book",
      count: res?.published_courses,
      heading: `Courses Published`,
    },
    {
      iconColor: res?.certificates ? "blue" : "grey",
      iconName: "fa-solid fa-file-circle-check",
      count: res?.certificates,
      heading: "Certificates Issued",
    },
    {
      iconColor: res?.students ? "orange" : "grey",
      iconName: "fa-solid fa-users",
      count: res?.students,
      heading: "Students Regsitered",
    },
  ];
};

export const sortItems = (items, orderBy = "ASC") => {
  if (orderBy === "DESC") {
    return items.sort((item1, item2) => (item1 < item2 ? 1 : -1));
  }
  return items.sort((item1, item2) => (item1 > item2 ? 1 : -1));
};

export const formatChartDataByGrade = (
  grades,
  enrollments,
  certificates,
  contentRequested,
  contentCompleted,
  totalContentCompleted,
) => {
  const barData = grades?.data
    ?.map((grade) => grade.attributes.name)
    ?.map((grade) => {
      return {
        grade,
        enrollments: enrollments?.[grade] || 0,
        certificates: certificates?.[grade] || 0,
        contentRequested: contentRequested?.[grade] || 0,
        contentCompleted: contentCompleted?.[grade] || 0,
        totalContentCompleted: totalContentCompleted?.[grade] || 0,
      };
    });

  const barChartHasData = barData?.some(
    (item) =>
      item.enrollments !== 0 ||
      item.certificates !== 0 ||
      item.contentRequested !== 0 ||
      item.contentCompleted !== 0 ||
      item.totalContentCompleted !== 0,
  );

  return {
    data: barData,
    hasData: barChartHasData,
  };
};

export const formatChartDataByCountries = (
  type,
  items,
  registrations,
  enrollments,
  certificates,
  contentRequested,
  contentCompleted,
  totalContentCompleted,
) => {
  const barData = items?.map((item) => {
    const data = {};
    data[type] = item;
    return {
      ...data,
      registrations: registrations?.[item] || 0,
      enrollments: enrollments?.[item] || 0,
      certificates: certificates?.[item] || 0,
      contentRequested: contentRequested?.[item] || 0,
      contentCompleted: contentCompleted?.[item] || 0,
      totalContentCompleted: totalContentCompleted?.[item] || 0,
    };
  });

  const barChartHasData = barData?.some(
    (item) =>
      item.registrations !== 0 ||
      item.enrollments !== 0 ||
      item.certificates !== 0 ||
      item.contentRequested !== 0 ||
      item.contentCompleted !== 0 ||
      item.totalContentCompleted !== 0,
  );

  return {
    data: barData,
    hasData: barChartHasData,
  };
};

export const getAllCountries = (locations) => {
  if (!locations?.countries) return [];
  return sortItems(Object.keys(locations?.countries), "ASC");
};

export const getAllStates = (locations) => {
  if (!locations?.afg_states) return [];
  return sortItems(Object.keys(locations?.afg_states), "ASC");
};

const countTotalValues = (obj) => {
  let total = 0;
  for (let key in obj) {
    if (typeof obj[key] === "number") {
      total += obj[key];
    }
  }
  return total;
};

export const handleGradeLegendData = (
  enrollments,
  certificates,
  contentRequested,
  contentCompleted,
  totalContentCompleted,
) => {
  let enrollmentsCount = 0;
  let certificatesCount = 0;
  let contentRequestedCount = 0;
  let contentCompletedCount = 0;
  let totalContentCompletedCount = 0;

  enrollmentsCount = countTotalValues(enrollments ?? {}) ?? 0;
  certificatesCount = countTotalValues(certificates ?? {}) ?? 0;
  contentRequestedCount = countTotalValues(contentRequested ?? {}) ?? 0;
  contentCompletedCount = countTotalValues(contentCompleted ?? {}) ?? 0;
  totalContentCompletedCount =
    countTotalValues(totalContentCompleted ?? {}) ?? 0;

  return {
    enrollmentsCount,
    certificatesCount,
    contentRequestedCount,
    contentCompletedCount,
    totalContentCompletedCount,
  };
};

export const handleCountryLegendData = (selectedCountry, statisticsGroup) => {
  let registrationsCount = 0;
  let enrollmentsCount = 0;
  let certificatesCount = 0;
  let contentRequestedCount = 0;
  let contentCompletedCount = 0;
  let totalContentCompletedCount = 0;
  if (selectedCountry) {
    registrationsCount =
      countTotalValues(statisticsGroup?.registrations_by_country_state ?? {}) ??
      0;
    enrollmentsCount =
      countTotalValues(statisticsGroup?.enrollments_by_country_state ?? {}) ??
      0;
    certificatesCount =
      countTotalValues(statisticsGroup?.certificates_by_country_state ?? {}) ??
      0;
    contentRequestedCount =
      countTotalValues(
        statisticsGroup?.content_requests_by_country_state ?? {},
      ) ?? 0;
    contentCompletedCount =
      countTotalValues(
        statisticsGroup?.content_completions_by_country_state ?? {},
      ) ?? 0;
    totalContentCompletedCount =
      countTotalValues(
        statisticsGroup?.total_content_completions_by_country_state ?? {},
      ) ?? 0;
  } else {
    registrationsCount =
      countTotalValues(statisticsGroup?.registrations_by_country ?? {}) ?? 0;
    enrollmentsCount =
      countTotalValues(statisticsGroup?.enrollments_by_country ?? {}) ?? 0;
    certificatesCount =
      countTotalValues(statisticsGroup?.certificates_by_country ?? {}) ?? 0;
    contentRequestedCount =
      countTotalValues(statisticsGroup?.content_requests_by_country ?? {}) ?? 0;
    contentCompletedCount =
      countTotalValues(statisticsGroup?.content_completions_by_country ?? {}) ??
      0;
    totalContentCompletedCount =
      countTotalValues(
        statisticsGroup?.total_content_completions_by_country ?? {},
      ) ?? 0;
  }
  return {
    registrationsCount,
    enrollmentsCount,
    certificatesCount,
    contentRequestedCount,
    contentCompletedCount,
    totalContentCompletedCount,
  };
};

export const payload = ({
  page,
  limit,
  courseId,
  gradeId,
  serviceNumberId,
  teacherId,
  attemptNumber,
  completionStatus,
  certificateGenerated,
}) => ({
  page,
  limit,
  course_id: courseId,
  grade_id: gradeId,
  service_number_id: serviceNumberId,
  teacher_id: teacherId,
  attempt_number: attemptNumber,
  completion_status: completionStatus,
  certificate_generated: certificateGenerated,
});

export const handleLegends = (
  dataKey,
  legendField,
  state,
  startDate,
  endDate,
) => {
  if (legendField) {
    const query = queryParams({
      ...state,
      startDate,
      endDate,
    });
    if (dataKey === "contentRequested") {
      dataKey = "lesson_requested";
    }
    if (dataKey === "certificates") {
      dataKey = "certificate_issued";
    }
    window.location.href = `/students?activity=${dataKey}&${new URLSearchParams(query)}`;
  }
};

export const CustomXAxisTick = ({ x, y, payload, handleClick }) => {
  if (!payload.value) return null;

  const isAfghanistan = payload.value.includes("Afghanistan");
  const tickStyle = {
    fontSize: 12,
    cursor: isAfghanistan ? "pointer" : "default",
    textDecoration: isAfghanistan ? "underline" : "none",
    color: isAfghanistan ? "blue" : "black",
  };

  return (
    <text
      x={x}
      y={y + 10} // Adjust vertical positioning
      style={tickStyle}
      onClick={() => isAfghanistan && handleClick(payload.value)}
      textAnchor="middle" // Center the text
      fill={isAfghanistan ? "blue" : "black"}
      transform={`rotate(-25, ${x}, ${y + 10})`}
    >
      {payload.value}
    </text>
  );
};

export const handleLegendMouseOver = ({ dataKey }, index, legendData) => {
  if (!["contentRequested", "certificates"].includes(dataKey)) return;
  const legendElement = document.querySelector(
    `.recharts-legend-item.legend-item-${index}`,
  );
  if (legendElement) {
    const styles = legendElement.getAttribute("style");
    if (!styles.includes("cursor: pointer") && legendData[dataKey + "Count"])
      legendElement.setAttribute("style", `${styles}cursor: pointer`);
  }
};

export const getLegendStyle = (dataKey, legendData) => {
  const countKey = dataKey + "Count";

  return legendData[countKey] > 0 &&
    (dataKey === "certificates" || dataKey === "contentRequested")
    ? { textDecoration: "underline" }
    : {};
};

const queryParams = ({
  startDate,
  endDate,
  courseType,
  gradeIds,
  courseIds,
  age,
  gender,
  locationId,
  provinceIds,
}) => ({
  activity_start_date: startDate,
  activity_end_date: endDate,
  courseType,
  grades: gradeIds,
  courses: courseIds,
  age,
  gender,
  country: locationId,
  country_state: locationId == "Afghanistan" ? provinceIds : "",
});
