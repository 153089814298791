import React from "react";
import { useNavigate } from "react-router-dom";
import { Table as RsuiteTable } from "rsuite";
import { ordinalize } from "../../../utils/ordinalize";

const {
  Column,
  Cell: OriginalCell,
  HeaderCell: OriginalHeaderCell,
} = RsuiteTable;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const Table = ({
  isLoading,
  courses,
  page,
  limit,
  handleEdit,
  handleDelete,
}) => {
  const navigate = useNavigate();
  return (
    <RsuiteTable
      loading={isLoading}
      height={500}
      width={"100wv"}
      data={courses}
    >
      <Column width={100}>
        <HeaderCell>No</HeaderCell>
        <Cell>
          {(rowData, rowIndex) => (
            <div>{rowIndex + 1 + (page - 1) * limit}</div>
          )}
        </Cell>
      </Column>
      <Column width={100} flexGrow={3}>
        <HeaderCell>Title</HeaderCell>
        <Cell dataKey="attributes.title" />
      </Column>
      <Column width={100} flexGrow={3}>
        <HeaderCell>Description</HeaderCell>
        <Cell dataKey="attributes.description" />
      </Column>
      <Column width={100} flexGrow={3}>
        <HeaderCell className="text-center">Course Grade</HeaderCell>
        <Cell className="text-center">
          {(rowData) => {
            return rowData["attributes"]["grades"]
              ?.map(function (i) {
                return ordinalize(i);
              })
              ?.join(", ");
          }}
        </Cell>
      </Column>
      <Column width={100} flexGrow={3}>
        <HeaderCell>Language</HeaderCell>
        <Cell dataKey="attributes.language" />
      </Column>
      <Column width={100} flexGrow={2}>
        <HeaderCell>Enrolled</HeaderCell>
        <Cell dataKey="attributes.enrolled_count" />
      </Column>
      <Column width={100} flexGrow={2}>
        <HeaderCell>Certified</HeaderCell>
        <Cell dataKey="attributes.certified_count" />
      </Column>
      <Column width={100} flexGrow={3}>
        <HeaderCell>Published?</HeaderCell>
        <Cell>
          {(rowData) => (
            <div>{rowData["attributes"]["published"] ? "Yes" : "No"}</div>
          )}
        </Cell>
      </Column>
      <Column
        width={150}
        fixed="right"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <HeaderCell>Actions</HeaderCell>
        <Cell style={{ padding: "6px" }}>
          {(rowData) => {
            return (
              <div className="light-txt px-2 py-2">
                <i
                  className="fa-solid fa-eye me-2"
                  onClick={() =>
                    navigate(
                      `/courses/${rowData["id"]}/summary/?type=list&page=${page}`,
                    )
                  }
                  aria-hidden="true"
                ></i>
                <i
                  className="fa-solid fa-pencil me-2"
                  onClick={() => handleEdit(rowData)}
                  aria-hidden="true"
                ></i>
                <i
                  className="fa-solid fa-trash me-2"
                  onClick={() => handleDelete(rowData)}
                  aria-hidden="true"
                ></i>
              </div>
            );
          }}
        </Cell>
      </Column>
    </RsuiteTable>
  );
};

export default Table;
