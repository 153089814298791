import React from "react";

// ReCharts
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

// components
import NoDataFound from "../../../components/bar_chart/noDataFound";

// helpers
import {
  handleLegends,
  handleLegendMouseOver,
  getLegendStyle,
} from "../helper";

const GradeBarChart = ({
  state,
  hasData,
  gradeChartData,
  startDate,
  endDate,
  legendData,
}) => {
  if (!hasData) return <NoDataFound />;
  return (
    <ResponsiveContainer width="100%" height={380}>
      <BarChart data={gradeChartData ?? []}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="grade"
          label={{
            value: "Course Grades",
            position: "insideBottom",
            offset: "-9",
          }}
          tick={{ fontSize: 12 }}
          textAnchor="end"
          angle={-45}
        />
        <YAxis
          label={{
            value: "Numbers",
            angle: -90,
            position: "left",
            offset: "-3",
          }}
          allowDecimals={false}
        />
        <Tooltip />
        <Legend
          iconType="square"
          verticalAlign="top"
          height={60}
          onClick={({ dataKey }) => {
            if (
              !["contentRequested", "certificates"].includes(dataKey) ||
              !legendData[dataKey + "Count"]
            )
              return;
            handleLegends(
              dataKey,
              legendData[dataKey] + "Count",
              state,
              startDate,
              endDate,
            );
          }}
          onMouseOver={(e, i) => handleLegendMouseOver(e, i, legendData)}
          formatter={(value, entry) => {
            const style = getLegendStyle(entry.dataKey, legendData);
            return <span style={style}>{value}</span>;
          }}
        />
        <Bar
          name={`Enrollments (${legendData?.enrollmentsCount || 0})`}
          dataKey="enrollments"
          fill="#0d6d18"
        />
        <Bar
          name={`Certificates Issued (${legendData?.certificatesCount || 0})`}
          dataKey="certificates"
          fill="#18181a"
        />
        <Bar
          name={`Lessons Requested (${legendData?.contentRequestedCount || 0})`}
          dataKey="contentRequested"
          fill="#43C4C7"
        />
        {new Date(startDate) > new Date("2024-05-07") && (
          <Bar
            name={`Requested Lessons Completed (${legendData?.contentCompletedCount || 0})`}
            dataKey="contentCompleted"
            fill="#FF8042"
          />
        )}
        <Bar
          name={`Total Lessons Completed (${legendData?.totalContentCompletedCount || 0})`}
          dataKey="totalContentCompleted"
          fill="#B442FF"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
// End
export default GradeBarChart;
