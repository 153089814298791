import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import CourseItemForm from "./course_item_form";
import Spinner from "../../../components/spinner";
import { fetcher } from "../../../utils/fetcher";
import { Table, Button } from "rsuite";
import LessonContent from "./lesson_content";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndRow, handleDndRow } from "../../../utils/dnd";
import ConfirmModal from "../../../components/confirm_modal";
import axios from "axios";
import { getAuthenticityToken } from "../../../utils/authenticity_token";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

export const newCourseItem = () => ({
  attributes: {
    title: "",
    content: "",
    type: "",
    delivery_day: 1,
    position: 1,
    data: null,
  },
});

const Content = () => {
  const { id: courseId } = useParams();

  const {
    data: lessonsData = { data: [] },
    isLoading: lessonsIsLoading,
    mutate: mutateLessons,
  } = useSWR(
    {
      url: "/api/v0/lessons",
      params: {
        course_id: courseId,
      },
    },
    fetcher,
  );

  const [lesson, setLesson] = useState(null);
  const [showCourseItemForm, setShowCourseItemForm] = useState(false);

  const backToLessons = () => {
    setLesson(null);
    mutateLessons();
  };

  const [showDndConfirmModal, setShowDndConfirmModal] = useState(null);
  const handleDndLesson = (sourceId, targetId) => {
    let dndLesson = handleDndRow(lessonsData?.data, sourceId, targetId);
    if (dndLesson) {
      setShowDndConfirmModal(dndLesson);
    }
  };

  if (lessonsIsLoading) return <Spinner />;

  return (
    <>
      <div>
        {/* go back to lessons button */}
        {lesson && (
          <Button
            appearance="ghost"
            className="px-3 me-2"
            color="green"
            onClick={backToLessons}
          >
            <i className="fa fa-less-than me-2"></i>
            Lessons
          </Button>
        )}
        {/* add course item button */}
        <Button
          appearance="ghost"
          className="px-3 me-2"
          color="green"
          onClick={() => setShowCourseItemForm(true)}
        >
          <i className="fa fa-plus me-2"></i>
          Add Course Item
        </Button>
      </div>
      {/* if lesson is selected, display lesson content else display list of lessons */}
      {lesson ? (
        <LessonContent
          key={lesson?.attributes?.course_item_count}
          lesson={lesson}
          courseId={courseId}
          backToLessons={backToLessons}
        />
      ) : (
        <div className="mx--3">
          {/* lessons table */}
          <div className="shadow-sm bg-white radius-5">
            <div className="inner">
              <DndProvider backend={HTML5Backend}>
                <Table
                  loading={lessonsIsLoading}
                  height={500}
                  width={"100wv"}
                  data={lessonsData?.data || []}
                  onRowClick={(data) => {
                    setLesson(data);
                  }}
                  renderRow={(children, rowData) => {
                    return rowData ? (
                      <DndRow
                        key={rowData.id}
                        rowData={rowData}
                        id={rowData.id}
                        onDrag={handleDndLesson}
                      >
                        {children}
                      </DndRow>
                    ) : (
                      children
                    );
                  }}
                >
                  <Column width={100} flexGrow={3}>
                    <HeaderCell>Lesson</HeaderCell>
                    <Cell dataKey="attributes.title" />
                  </Column>
                  <Column width={100} flexGrow={2}>
                    <HeaderCell>Total Course Items</HeaderCell>
                    <Cell dataKey="attributes.course_item_count" />
                  </Column>
                  <Column width={100} align="center" flexGrow={1}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                      <div className="light-txt px-2 py-1">
                        <i
                          className="icon-button fa-solid fa-greater-than me-2"
                          role="button"
                        ></i>
                      </div>
                    </Cell>
                  </Column>
                </Table>
              </DndProvider>
            </div>
          </div>
        </div>
      )}
      {/* add new course item */}
      {showCourseItemForm && (
        <CourseItemForm
          courseId={courseId}
          open={showCourseItemForm}
          lesson={lesson}
          onClose={() => {
            setShowCourseItemForm(false);
          }}
          onConfirm={() => {
            setShowCourseItemForm(false);
            mutateLessons();
          }}
        />
      )}
      {/* course item dnd  */}
      {showDndConfirmModal && (
        <ConfirmModal
          open={!!showDndConfirmModal}
          message={`Are you sure you want to move the Lesson ${showDndConfirmModal.source.attributes.position} 
            from position ${showDndConfirmModal.source.attributes.position}
            to ${showDndConfirmModal.newSourcePosition}?`}
          onClose={() => setShowDndConfirmModal(null)}
          onConfirm={() => {
            axios({
              method: "PUT",
              url: `/api/v0/lessons/${showDndConfirmModal.source.id}`,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
              data: {
                lesson: {
                  position: showDndConfirmModal.newSourcePosition,
                },
              },
            })
              .then(() => {
                setShowDndConfirmModal(null);
                mutateLessons();
                return;
              })
              .catch((err) => {
                console.log(err.response.data.errors);
              });
          }}
        />
      )}
    </>
  );
};

export default Content;
